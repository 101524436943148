import React from 'react';

const About = () => {
  return (
    <div id="about-block">
      <h1 id="black">About</h1>
      <p id="about">
        Welcome to the Aisle Tracker! Our mission is to help shoppers
        easily locate items in Costco stores, saving you time and
        making your shopping experience more convenient.
      </p>
      <p id="about">
        Whether you're searching for a specific product or just exploring what
        your Costco has to offer, our web app is here to guide you!
      </p>
      <p id="about">
        Note that not all items are listed that may be in the Costco store location. Items may have moved since last update but we strive to keep the web app updated monthly. The web app does not strive to give you pinpoint accuracy but strives to get you closer to the area of the item you are in search of.
      </p>
    </div>
  );
};

export default About;
